.spinner-wrapper{
   position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000080;
    color: white;
}